import { ENDPOINT_MAP } from '@mpe/api-client/config'
import getConfig from 'next/config'

export interface Metadata {
  title: string
  description: string
  imgUrl: string
}

interface Entity {
  type: string
  localizedName: string
  endpoint: string
  appendQuery?: {}
  metadata: Metadata
  //TODO: isTheme: boolean
}

const LOCALIZED_CATEGORY_NAMES: { [key: string]: string } = {
  award: 'Díjak',
  tag: 'Címkék',
  song: 'Dalok',
  event: 'Események',
  eventSeries: 'Eseménysorozatok',
  album: 'Galériák',
  audio: 'Audiók',
  venue: 'Helyszínek',
  institution: 'Intézmények',
  image: 'Képek',
  publication: 'Kiadványok',
  publicationItem: 'Kiadványelemek',
  musicalPeriod: 'Korszakok',
  person: 'Személyek',
  video: 'Videók',
  band: 'Előadók',
  childrenBand: 'Gyerekprodukciók'
}

const musicalPeriod: Entity = {
  type: 'MusicalPeriod',
  localizedName: LOCALIZED_CATEGORY_NAMES.musicalPeriod,
  endpoint: ENDPOINT_MAP.MusicalPeriod,
  metadata: {
    title: 'A magyar popkultúra korszakai',
    description:
      'Beat-korszak, diszkó, modern táncdal. A magyar popkultúra legfontosabb korszakai.',
    imgUrl: '/images/og-image/korszakok.png'
  }
}

const band: Entity = {
  type: 'Band',
  localizedName: LOCALIZED_CATEGORY_NAMES.band,
  endpoint: ENDPOINT_MAP.Band,
  metadata: {
    title: 'A magyar popkultúra megkerülhetetlen zenekarai',
    description:
      'Felejthetetlen, megkerülhetetlen és pótolhatatlan zenekarok a magyar popkultúrában.',
    imgUrl: '/images/og-image/zenekarok.png'
  }
}

const childrenBand: Entity = {
  type: 'Band',
  localizedName: LOCALIZED_CATEGORY_NAMES.childrenBand,
  endpoint: ENDPOINT_MAP.Band,
  metadata: {
    title: 'A magyar popkultúra megkerülhetetlen zenekarai',
    description:
      'Felejthetetlen, megkerülhetetlen és pótolhatatlan zenekarok a magyar popkultúrában.',
    imgUrl: '/images/og-image/zenekarok.png'
  }
}

const publicRuntimeConfig = getConfig().publicRuntimeConfig || {}
if (publicRuntimeConfig.childrenBandsTag && publicRuntimeConfig.childrenBandsTag !== '') {
  band.appendQuery = {
    'withoutTags[]': publicRuntimeConfig.childrenBandsTag
  }
  childrenBand.appendQuery = {
    'withTags[]': publicRuntimeConfig.childrenBandsTag
  }
}

const person: Entity = {
  type: 'Person',
  localizedName: LOCALIZED_CATEGORY_NAMES.person,
  endpoint: ENDPOINT_MAP.Person,
  metadata: {
    title: 'A magyar popkultúra legnagyobb alakjai',
    description:
      'Lázadók és újítók, frontemberek és háttérsegítők a magyar popkultúra történelméből.',
    imgUrl: '/images/og-image/szemelyek.png'
  }
}

const event: Entity = {
  type: 'Event',
  localizedName: LOCALIZED_CATEGORY_NAMES.event,
  endpoint: ENDPOINT_MAP.Event,
  metadata: {
    title: 'A magyar popkultúra felejthetetlen pillanatai',
    description: 'Koncertek és fesztiválok a magyar popkultúra több mint 60 évéből.',
    imgUrl: '/images/og-image/esemenyek.png'
  }
}

const eventSeries: Entity = {
  type: 'EventSeries',
  localizedName: LOCALIZED_CATEGORY_NAMES.eventSeries,
  endpoint: ENDPOINT_MAP.EventSeries,
  metadata: {
    title: 'A magyar popkultúra felejthetetlen pillanatai',
    description:
      'Koncerteksorozatok és visszatérő fesztiválok a magyar popkultúra elmúlt 60 évéből.',
    imgUrl: '/images/og-image/esemenysorozatok.png'
  }
}

const venue: Entity = {
  type: 'Venue',
  localizedName: LOCALIZED_CATEGORY_NAMES.venue,
  endpoint: ENDPOINT_MAP.Venue,
  metadata: {
    title: 'A magyar popkultúra legfontosabb helyszínei',
    description: 'Klubok, művházak és stadionok. A hazai popkultúra megszületésének helyszínei.',
    imgUrl: '/images/og-image/helyszinek.png'
  }
}

const publication: Entity = {
  type: 'Publication',
  localizedName: LOCALIZED_CATEGORY_NAMES.publication,
  endpoint: ENDPOINT_MAP.Publication,
  metadata: {
    title: 'A magyar popkultúra legfontosabb kiadványai',
    description: 'Dalok, lemezek, műsorok és magazinok a magyar popkultúra elmúlt évtizedeiből.',
    imgUrl: '/images/og-image/kiadvanyok.png'
  }
}

const publicationItem: Entity = {
  type: 'PublicationItem',
  localizedName: LOCALIZED_CATEGORY_NAMES.publicationItem,
  endpoint: ENDPOINT_MAP.PublicationItem,
  metadata: {
    title: 'A magyar popkultúra legfontosabb kiadványai',
    description: 'Dalok, lemezek, műsorok és magazinok a magyar popkultúra elmúlt évtizedeiből.',
    imgUrl: '/images/og-image/kiadvanyelemek.png'
  }
}

const album: Entity = {
  type: 'Album',
  localizedName: LOCALIZED_CATEGORY_NAMES.album,
  endpoint: ENDPOINT_MAP.Album,
  metadata: {
    title: 'Képek a magyar popkultúra történelméből',
    description:
      'Elkapott pillanatok és tökéletesre komponált zenekari fotók. 60 évnyi poptörténelem képekben.',
    imgUrl: '/images/og-image/galeriak.png'
  }
}

const video: Entity = {
  type: 'Video',
  localizedName: LOCALIZED_CATEGORY_NAMES.video,
  endpoint: ENDPOINT_MAP.Video,
  metadata: {
    title: 'Felvételek a magyar popkultúra történelméből',
    description: 'Koncertfelvételek a magyar könnyűzene meghatározó alakjairól.',
    imgUrl: '/images/og-image/videok.png'
  }
}

const audio: Entity = {
  type: 'Audio',
  localizedName: LOCALIZED_CATEGORY_NAMES.audio,
  endpoint: ENDPOINT_MAP.Audio,
  metadata: {
    title: 'Dalok a magyar popkultúra történelméből',
    description: 'Fontos kiadványok a magyar popkultúra történelméből.',
    imgUrl: '/images/og-image/audiok.png'
  }
}

const song: Entity = {
  type: 'Song',
  localizedName: LOCALIZED_CATEGORY_NAMES.song,
  endpoint: ENDPOINT_MAP.Song,
  metadata: {
    title: 'Dalok a magyar popkultúra történelméből',
    description: 'Fontos kiadványok a magyar popkultúra történelméből.',
    imgUrl: '/images/og-image/audiok.png'
  }
}

const image: Entity = {
  type: 'Image',
  localizedName: LOCALIZED_CATEGORY_NAMES.image,
  endpoint: ENDPOINT_MAP.Image,
  metadata: {
    title: 'Képek a magyar popkultúra történelméből',
    description: 'Emlékezetes fotók a magyar popkultúra történelméből.',
    imgUrl: '/images/og-image/kepek.png'
  }
}

const tag: Entity = {
  type: 'Tag',
  localizedName: LOCALIZED_CATEGORY_NAMES.tag,
  endpoint: ENDPOINT_MAP.Tag,
  metadata: {
    title: 'A magyar popkultúra felejthetetlen pillanatai',
    description: 'A magyar popkultúra felejthetetlen pillanatai',
    imgUrl: '/images/og-image/kepek.png'
  }
}

const award: Entity = {
  type: 'Award',
  localizedName: LOCALIZED_CATEGORY_NAMES.award,
  endpoint: ENDPOINT_MAP.Award,
  metadata: {
    title: 'A magyar popkultúra megkerülhetetlen zenekarai',
    description:
      'Felejthetetlen, megkerülhetetlen és pótolhatatlan zenekarok a magyar popkultúrában.',
    imgUrl: '/images/og-image/zenekarok.png'
  }
}

const institution: Entity = {
  type: 'Institution',
  localizedName: LOCALIZED_CATEGORY_NAMES.institution,
  endpoint: ENDPOINT_MAP.Institution,
  metadata: {
    title: 'A magyar popkultúra megkerülhetetlen zenekarai',
    description:
      'Felejthetetlen, megkerülhetetlen és pótolhatatlan zenekarok a magyar popkultúrában.',
    imgUrl: '/images/og-image/zenekarok.png'
  }
}

export const PATH_TO_ENTITY: { [key: string]: Entity } = {
  korszak: musicalPeriod,
  eloado: band,
  gyerekzenekar: childrenBand,
  szemely: person,
  esemeny: event,
  esemenysorozat: eventSeries,
  helyszin: venue,
  kiadvany: publication,
  kiadvanyelem: publicationItem,
  galeria: album,
  video: video,
  audio: audio,
  kep: image,
  dal: song,
  cimke: tag,
  dij: award,
  intezmeny: institution
}

// export const PATH_TO_ENTITY: { [key: string]: string[] } = {
//   cimke: ['Tag', LOCALIZED_CATEGORY_NAMES.tag, ENDPOINT_MAP.Tag],
//   dal: ['Song', LOCALIZED_CATEGORY_NAMES.song, ENDPOINT_MAP.Song],
//   dij: ['Award', LOCALIZED_CATEGORY_NAMES.award, ENDPOINT_MAP.Award],
//   esemeny: ['Event', LOCALIZED_CATEGORY_NAMES.event, ENDPOINT_MAP.Event],
//   esemenysorozat: ['EventSeries', LOCALIZED_CATEGORY_NAMES.eventSeries, ENDPOINT_MAP.EventSeries],
//   galeria: ['Album', LOCALIZED_CATEGORY_NAMES.album, ENDPOINT_MAP.Album],
//   hang: ['Audio', LOCALIZED_CATEGORY_NAMES.audio, ENDPOINT_MAP.Audio],
//   helyszin: ['Venue', LOCALIZED_CATEGORY_NAMES.venue, ENDPOINT_MAP.Venue],
//   intezmeny: ['Institution', LOCALIZED_CATEGORY_NAMES.institution, ENDPOINT_MAP.Institution],
//   kep: ['Image', LOCALIZED_CATEGORY_NAMES.image, ENDPOINT_MAP.Image],
//   kiadvany: ['Publication', LOCALIZED_CATEGORY_NAMES.publication, ENDPOINT_MAP.Publication],
//   kiadvanyelem: [
//     'PublicationItem',
//     LOCALIZED_CATEGORY_NAMES.publicationItem,
//     ENDPOINT_MAP.PublicationItem
//   ],
//   korszak: ['MusicalPeriod', LOCALIZED_CATEGORY_NAMES.musicalPeriod, ENDPOINT_MAP.MusicalPeriod],
//   szemely: ['Person', LOCALIZED_CATEGORY_NAMES.person, ENDPOINT_MAP.Person],
//   video: ['Video', LOCALIZED_CATEGORY_NAMES.video, ENDPOINT_MAP.Video],
//   zenekar: ['Band', LOCALIZED_CATEGORY_NAMES.band, ENDPOINT_MAP.Band]
// }
